@mixin background-gradient($color) {
  @if type-of($color) == 'list' {
    background: linear-gradient(nth($color, 1), nth($color, 2));
  } @else {
    background: $color;
  }
}

@mixin hover {
  &:hover:not(.disabled):not(:disabled) { @content }
  &.ppl-button--focusable:not(.disabled):not(:disabled):focus { @content }
}

@mixin button-active {
  &:not(.disabled):not(:disabled) {
    &:focus,
    &.ppl-button--active {
      @content
    }
  }
}

@mixin button-pressed {
  &:not(.disabled):not(:disabled) {
    &.ppl-button--pressed {
      @content
    }
  }
}

@mixin button-selected {
  &:not(.disabled):not(:disabled) {
    &.ppl-button--selected {
      @content
    }
  }
}

@mixin button-variant($color, $background, $border: false, $inner-shadow: false) {
  @include background-gradient($background);
  color: $color;

  @if $border {
    border-color: $border;
  }

  @if $inner-shadow {
    box-shadow: inset 0 1px 0 0 $inner-shadow,
                inset 0 -1px 0 0 rgba($inner-shadow, 0.5),
                inset 1px 0 0 0 rgba($inner-shadow, 0.5),
                inset -1px 0 0 0 rgba($inner-shadow, 0.5)
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @include background-gradient($background);
    border-color: $border;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color;
  }
}

// Hover and focus styles are shared
@mixin button-hover($color, $bg, $border: false, $inner-shadow: false) {
  @include hover {
    color: $color;
    @include background-gradient($bg);
    @if $border {
      border-color: $border;
    }
    @if $inner-shadow {
      box-shadow: inset 0 1px 0 0 $inner-shadow,
      inset 0 -1px 0 0 rgba($inner-shadow, .5),
      inset 1px 0 0 0 rgba($inner-shadow, .5),
      inset -1px 0 0 0 rgba($inner-shadow, .5)
    }
  }
  @include button-active {
    color: $color;
    @include background-gradient($bg);
    border-color: $border;
    @if $inner-shadow {
      box-shadow: inset 0 1px 0 0 $inner-shadow,
      inset 0 -1px 0 0 rgba($inner-shadow, .5),
      inset 1px 0 0 0 rgba($inner-shadow, .5),
      inset -1px 0 0 0 rgba($inner-shadow, .5)
    }
  }
  @include button-selected {
    color: #fff;
    background: #4d7cb0;
    border-color: #4d7cb0;
    box-shadow: none;
  }
  @include button-pressed {
    background: #e8edf6;
    box-shadow: inset 0 0 0 1px #d5dde4;
    border-color: #95bbdf;
  }
}

@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

//
// Base styles
//
.ppl-button {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  @include button-size(0.5rem, 1rem, 14px, 1.25, 4px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // Share hover and focus styles
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: 0.65;
    cursor: default;
  }

  //
  // Alternate buttons
  //

  &--1 {
    @include button-variant(#26345b, (#cfdef0, #bdd1e9), #95bbdf, #fff);
    @include button-hover(#384e61, (#ffe2ba, #ffd099), #e39c4c, #fff);
  }
  &--2 {
    @include button-variant(#384e61, #d3dce5, #24466c);
    @include button-hover(#384e61, #fee8c8, #24466c);
  }
  &--3 {
    @include button-variant(#fff, #28537d, #24476f);
    @include button-hover(#424b66, #fee8c8, #24476f);
  }
  &--4 {
    @include button-variant(#26345b, (#d2ebae, #c5dd98), #9bb67a, #f0f8e2);
    @include button-hover(#384e61, (#ffe2ba, #ffd099), #e39c4c, #fff);
  }
  &--5 {
    @include button-variant(#26345b, (#e8b4b3, #daa19e), #b27f7e, #f7e5e4);
    @include button-hover(#384e61, (#ffe2ba, #ffd099), #e39c4c, #fff);
  }
  &--6 {
    @include button-variant(#fff, #d54135, #c1272d, #e68d86);
    @include button-hover(#fff, #ad2e24, #a32120, #c36861);
  }

  &--7-default {
    @include button-variant(#fff, #2c5284);
    @include button-hover(#fff, #22446b);
  }

  &--7-info {
    @include button-variant(#fff, #67a615);
    @include button-hover(#fff, #528211);
  }

  &--7-warning {
    @include button-variant(#26345b, #fcdd32);
    @include button-hover(#26345b, #daba03);
  }

  &--7-danger {
    @include button-variant(#fff, #F4781F);
    @include button-hover(#fff, #CC7716);
  }

  &--7-error {
    @include button-variant(#fff, #bd0f1e);
    @include button-hover(#fff, #9f260a);
  }
  &--7-secondary {
    @include button-variant(#26345B, #D2DEEB, #A5BED7);
    @include button-hover(#26345B, #fee8c8, #A5BED7);
  }

  &--7-white {
    @include button-variant(#4c7db0, #ffffff);
    @include button-hover(#4c7db0, #ffe8cd);
  }
  &--7-outline {
    @include button-variant(#26345B, transparent, #D2DEEB);
    @include button-hover(#26345B, #ffe8cd);
  }

  //
  // Button Sizes
  //

  &--large {
    @include button-size(0.5rem, 1rem, 16px, 1.5, 4px);
  }

  &--small {
    @include button-size(0.25rem, 0.5rem, 12px, 1.5, 4px);
  }

  &--small-xs {
    @include button-size(0.25rem, 0.5rem, 12px, 1.5, 4px);
  }
  &--small-xxs {
    @include button-size(2px, 0.5rem, 12px, 1.5, 4px);
  }

  //
  // Button Style
  //
  &--bold {
    font-weight: 700;
  }

  &--save {
    @include button-variant(#384e61, #d3dce5, #24466c);
    @include button-hover(#384e61, #fee8c8, #24466c);
  }

  &--save-xs {
    @include button-variant(#384e61, #d3dce5, #24466c);
    @include button-hover(#384e61, #fee8c8, #24466c);
  }

  &--cancel {
    @include button-variant(#fff, #28537d, #24476f);
    @include button-hover(#424b66, #fee8c8, #24476f);
  }

  &--dialog, &--alert {
    min-width: 86px;
    height: 31px;
    padding: 3px 10px 4px;
    margin-left: 1px;
    font-size: 15px;
    text-align: center;
  }
}

// Button style for action buttons in dialogs
.ppl-button--dialog.ppl-button--small {
  @include button-size(0.25rem, 0.5rem, 12px, 1.5, 4px);
  height: auto;
  min-width: 60px;
}

// Future-proof disabling of clicks on `<a>` elements
a.ppl-button.disabled,
fieldset[disabled] a.ppl-button {
  pointer-events: none;
}

//
// Link buttons
//

// Make a button look and behave like a link
.ppl-button-link {
  font-weight: normal;
  color: #2b7dd4;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &:disabled {
    background-color: transparent;
  }
  &,
  &:focus,
  &:active {
    border-color: transparent;
  }
  @include hover {
    border-color: transparent;
  }
  &:focus,
  &:hover {
    color: #1e5894;
    text-decoration: underline;
    background-color: transparent;
  }
  &:disabled {
    color: #000;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

//
// Block button
//

.ppl-button--block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.ppl-button--block + .ppl-button--block {
  margin-top: 0.5rem;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.ppl-button--block {
    width: 100%;
  }
}

//
// Button groups
//
.btn-group {
  display: flex;

  .ppl-button:hover {
    z-index: 1;
  }

  .ppl-button:focus {
    z-index: 2;
  }

  .ppl-button:not(:first-child):not(:last-child) {
    border-radius: 0;
    margin-left: -1px;
  }

  .ppl-button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ppl-button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
}

.ppl-button--block-default {
  border-radius: 0;
  height: 34px;
  line-height: 34px;
  background: #edf2f7;
  color: #26345b;
  font-size: 12px;
  padding: 0;
  margin: 0;
  border: 0;
  text-align: center;
  cursor: pointer;

  & ppl-icon {
    margin-right: 5px;
    vertical-align: middle;
  }

  &:hover {
    background-color: #ffe8cd;
  }
  &:focus,
  &.focus {
    outline: 0;
  }
}
