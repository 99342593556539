@keyframes pplrotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ppl-rotate {
  animation: pplrotate 2s linear infinite;

  &-quick {
    animation-duration: 1s;
  }
}
