@import "prelude-pipeliner";
@import "default-datepicker";

/* You can add global styles to this file, and also import other style files */

@import "~cropperjs/dist/cropper.css";

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: Roboto, Arial, sans-serif;
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

.components-showcase-container {
  background-color: #f6f8fb;

  .container {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }

  .demo-content {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  pre[class*='language-'] {
    background: darken(#f6f8fb, 5%) !important;
    white-space: pre-wrap;
  }
}
