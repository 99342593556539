.ppl-input-container {
  position: relative;
  display: block;
  margin-bottom: 16px;
}

@mixin field-highlight {
  background: #fff1cc;
  border-color: #95beea;
}

.ppl-input {
  border: 1px solid var(--ppl-field-color, #aacbee);
  border-radius: 4px;
  color: #113255;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  padding: 0 6px;
  display: block;
  width: 100%;
  background: var(--ppl-field-color-0-1, #ffffff);
  font-size: 14px;
  outline: 0;
  transition: background-color 100ms linear;

  &:hover {
    border-color: #ffbb00;
  }

  &:focus {
    border-color: #ffab66;
    background: #ffffff;
  }

  &::placeholder {
    font-weight: 300;
    color: #bfcdd9;
  }

  &[disabled],
  &.disabled {
    background: #f2f2f2;
    color: #999999;
    border-color: #cccccc;
  }

  &[readonly],
  &.readonly {
    background: #f7fafd;
    color: #4b6881;
    border-color: #aacbee;
  }

  &--autofill {
    @include field-highlight;
  }

  .ppl-input-container--error & {
    background: #fde7e9 !important;
    border-color: #bd0f1e !important;
  }
  .ppl-input-container--w-icon & {
    padding-right: 30px;
  }

  .ppl-input-container--w-btn & {
    display: inline-block;
    width: calc(100% - 33px);
    vertical-align: top;
    margin-right: -1px;
  }

  .ppl-input-container--changes & {
    &:not(:focus) {
      @include field-highlight;
    }
  }

  .form-preview & {
    border: 1px solid var(--ppl-field-color, #cccccc) !important;
    background: var(--ppl-field-color-0-1, #f2f2f2) !important;
  }
}

.ppl-label {
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 400;
  color: var(--ppl-field-color, #26345b);

  .ppl-input-container--error & {
    color: #bd0f1e;
  }
}

textarea.ppl-input {
  height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 1.25;
}

.ppl-input__btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0;
}

.ppl-input__icon {
  display: inline-block;
  position: absolute;
  right: 8px;
  bottom: 8px;
}
