.datepicker {
  border-radius: 0 !important;
  padding: 0 !important;

  &-inline {
    width: 192px !important;
  }

  .datepicker-switch {
    font-size: 15px !important;
    font-weight: normal !important;
    text-transform: uppercase !important;
    color: #2c475e !important;
    &::last-word {
      color: #d96c36 !important;
    }
  }
  &:before,
  &:after {
    content: none !important;
  }
  thead {
    tr:nth-child(2) {
      &:after {
        content: "";
        display: block;
        height: 5px;
      }
    }
  }
  th {
    color: #8595a2;
    font-size: 12px;
    line-height: 22px;
    font-weight: 700;
    text-align: center;
    padding: 0;
    border-radius: 0 !important;
    &.dow {
      padding-top: 10px;
    }
    &.prev,
    &.next {
      &:hover {
        background: none !important;
      }
    }
  }
  td {
    border: 1px solid #cddbec;
    padding: 0;
    border-collapse: collapse;
  }
  .day {
    font-size: 11px;
    font-weight: bold;
    box-shadow: inset 1px 1px 0 0 #fff;
    text-align: center;
    background: #f3f8fa;
    cursor: pointer;
    box-sizing: border-box;
    outline: none;
    border: 0;
    margin: 0;
    color: #445c70;
    font-size: 12px;
    line-height: 15px;
    width: 30px;
    height: 26px;
    border-radius: 0;
    border-collapse: collapse;
    border: 1px solid #cedbec;
    &:hover {
      color: #445c70;
      background: #fee7c3;
      box-shadow: none;
      border-radius: 3px;
    }
    &.today {
      background: #dbeaef;
      text-decoration: underline;
      color: #26345b;
      font-weight: 700;
      border: 0 !important;
      &:hover {
        color: #445c70;
        background: #fee7c3 !important;
        box-shadow: none;
        border-radius: 3px;
      }
    }
    &.is-selected {
      color: #fff;
      font-weight: bold;
      background: #4e7fb2;
      padding: 4px;
      box-shadow: inset 0 0 1px 0 #fff;
    }
    &.active {
      color: #fff;
      font-weight: bold;
      border: 0 !important;
      background: #4e7fb2 !important;
      box-shadow: inset 0 0 1px 0 #fff;
    }
  }
}

.datepicker-days {
  .prev,
  .next {
    &:hover {
      svg {
        fill: #c94200;
      }
    }
  }
}

.ppl2-arrow {
  fill: #e4a185;
  > svg {
    height: 10px;
    width: 5px;
  }
}

.date-picker__footer {
  padding: 8px 1px 2px 1px;
  white-space: nowrap;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  button {
    display: flex;
    justify-content: center;
    width: 50%;
    &:first-child {
      margin-right: 5px;
    }
  }
}

.datepicker-months,
.datepicker-years,
.datepicker-decades,
.datepicker-centuries {
  td {
    padding-top: 5px;
    span {
      width: 25% !important;
      margin: 0 !important;
      border: 1px solid #cddbec;
      padding: 0;
      border-collapse: collapse;
      font-size: 11px;
      font-weight: bold;
      box-shadow: inset 1px 1px 0 0 #fff;
      text-align: center;
      background: #f3f8fa;
      cursor: pointer;
      box-sizing: border-box;
      outline: none;
      border: 0;
      margin: 0;
      color: #445c70;
      font-size: 12px;
      line-height: 61px;
      background: #f3f8fa;
      width: 30px;
      height: 61px !important;
      border-radius: 0 !important;
      border: 1px solid #cedbec;
      &:hover {
        color: #445c70;
        background: #fee7c3;
        box-shadow: none;
        border-radius: 3px;
      }
      &.is-selected {
        color: #fff;
        font-weight: bold;
        background: #4e7fb2;
        padding: 4px;
        box-shadow: inset 0 0 1px 0 #fff;
      }
      &.active {
        color: #fff;
        font-weight: bold;
        border: 0 !important;
        background: #4e7fb2 !important;
        box-shadow: inset 0 0 1px 0 #fff;
      }
      &:nth-child(1) {
        border-bottom: none;
        border-right: none;
      }
      &:nth-child(2) {
        border-bottom: none;
        border-right: none;
      }
      &:nth-child(3) {
        border-bottom: none;
        border-right: none;
      }
      &:nth-child(4) {
        border-bottom: none;
      }
      &:nth-child(5) {
        border-bottom: none;
        border-right: none;
      }
      &:nth-child(6) {
        border-bottom: none;
        border-right: none;
      }
      &:nth-child(7) {
        border-bottom: none;
        border-right: none;
      }
      &:nth-child(8) {
        border-bottom: none;
      }
      &:nth-child(9) {
        border-right: none;
      }
      &:nth-child(10) {
        border-right: none;
      }
      &:nth-child(11) {
        border-right: none;
      }
    }
  }
}
